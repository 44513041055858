import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { RBACRenderer } from "components/Auth";

const UserTableLoader = () => (
  <TableRow data-testid="user-table-row-loader">
    {[...Array(9)].map((_, index) => (
      <TableCell key={index} align="left">
        <Skeleton />
      </TableCell>
    ))}
    <RBACRenderer requiredPermissions={["can_modify_user_fleet_assoc"]}>
      <TableCell width={93} />
    </RBACRenderer>
  </TableRow>
);

export { UserTableLoader };
