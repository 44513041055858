import { RadioProps } from "@material-ui/core";
import { StyledRadio } from "./Radio.styles";
import { colors } from "ui/Theme/colors";

const Unchecked = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="6.5" stroke={colors.gray[950]} />
  </svg>
);

const Checked = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="6.5" stroke={colors.gray[950]} />
    <circle cx="8.5" cy="8.5" r="4.5" fill={colors.primary[500]} />
  </svg>
);

type Props = Omit<RadioProps, "color" | "disableRipple" | "disableFocusRipple" | "disableTouchRipple" | "small" | "icon" | "checkedIcon">;
const Radio = (props: Props) => {
  return (
    <StyledRadio
      color="primary"
      disableFocusRipple
      disableTouchRipple
      disableRipple
      size="small"
      icon={<Unchecked />}
      checkedIcon={<Checked />}
      {...props}
    />
  );
};

export { Radio };
