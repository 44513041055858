import { ChangeEvent, ForwardedRef, forwardRef, memo, useEffect, useMemo, useRef, useState } from "react";
import { Search } from "@material-ui/icons";
import { FormControlLabel, IconButton, InputBase, Menu, Checkbox, MenuItem } from "@material-ui/core";

import { useMenu } from "hooks";

import { MultiSelectProps } from "./MultiSelect.types";
import { CheckboxLabel, NoDataPlaceholder, SearchBar, MultiSelectLabelRoot } from "./MultiSelect.styles";
import { color } from "config/variable.styles";
import { convertToKebabCase } from "utils";

const MultiSelectCheckbox = memo(
  forwardRef(
    <T extends string = string>(
      props: MultiSelectProps<T>["options"][number] & {
        checked: boolean;
        italic?: boolean;
        testID: string;
      },
      ref: any
    ) => {
      const { name, value, checked, italic = false } = props;

      return (
        <MenuItem value={value} className="width-checkbox" ref={ref} data-testid={`${props.testID}-list-item-${value}`}>
          <FormControlLabel
            value={value}
            checked={checked}
            control={<Checkbox data-testid={`${props.testID}-checkbox-${value}`} checked={checked} name={name} color="primary" />}
            label={
              <CheckboxLabel className="checkbox-label" italic={italic}>
                <span className="label-text">{name}</span>
              </CheckboxLabel>
            }
          />
        </MenuItem>
      );
    }
  )
);

const MultiSelectContent = <T extends string = string>(props: Omit<MultiSelectProps<T>, "onChange">, ref: any) => {
  const { options, value, hideSearch = false, onClear, title, valuesToStickAtTop = [] } = props;
  const [text, setText] = useState("");
  const sortedOptions = useMemo(
    () =>
      [...options].sort((a, b) => {
        if (valuesToStickAtTop.includes(a.value) || valuesToStickAtTop.includes(b.value)) {
          return 0;
        }
        return a.name?.localeCompare(b.name || "") || 0;
      }),

    // We don't want to re-sort the options when valuesToStickAtTop array changes
    // Memoizing it through refs will be an overhead, so we are ignoring it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );
  const list = useMemo(() => sortedOptions.filter((el) => el.name?.toLowerCase().includes(text.trim().toLowerCase())), [sortedOptions, text]);

  // Instead of iterating over values to find selected items,
  // we can use an object to keep track of them
  const selectedMenuItems = useMemo(
    () =>
      value.reduce<Record<string, true>>((acc, item) => {
        acc[item] = true;
        return acc;
      }, {}),
    [value]
  );

  const testID = convertToKebabCase(title);

  return (
    <>
      {!hideSearch ? (
        <SearchBar onClickCapture={(e) => e.stopPropagation()} onKeyDownCapture={(e) => e.stopPropagation()}>
          <div className="search-group">
            <IconButton aria-label="search">
              <Search />
            </IconButton>
            <InputBase
              value={text}
              placeholder={`Search ${title}`}
              inputProps={{ "aria-label": `Search ${title}` }}
              onChange={(e) => {
                e.stopPropagation();
                setText(e.target.value);
              }}
              data-testid={`${testID}-search`}
            />
          </div>
        </SearchBar>
      ) : null}
      {!!onClear ? (
        <div className="list-auto-scroll-lable">
          <span
            style={{
              color: !!value.length ? color.TurquoiseBlueThree : "gray",
              cursor: !!value.length ? "pointer" : "default",
            }}
            className="clear-Selected-Items"
            onClick={onClear}
            role="button"
            data-testid={`${testID}-clear`}
          >
            Clear Selected Items
          </span>
        </div>
      ) : null}
      <div className="list-auto-scroll">
        {!!list.length ? (
          list.map((item, index) => (
            <MultiSelectCheckbox
              ref={ref}
              key={index}
              testID={testID}
              {...item}
              // Make text italic for all values to be sticked to top
              // We are using this to make top sticked values italic
              // Instead of checking if current text value is one of the values to stick at top,
              // It will be easy to check if current index is less than length of valuesToStickAtTop
              // Because, the array is already sorted to have such values at top
              italic={index < valuesToStickAtTop.length && valuesToStickAtTop.includes(item.value)}
              checked={selectedMenuItems[item.value] ?? false}
            />
          ))
        ) : (
          <NoDataPlaceholder>No results found</NoDataPlaceholder>
        )}
      </div>
    </>
  );
};

const MultiSelectContentWithRef = forwardRef(MultiSelectContent) as <T extends string = string>(
  props: Omit<MultiSelectProps<T>, "onChange"> & { ref?: ForwardedRef<HTMLDivElement> }
) => JSX.Element;

const MultiSelect = <T extends string = string>(props: MultiSelectProps<T>) => {
  const {
    testID,
    value,
    onClear,
    disabled,
    options,
    title,
    LabelComponent = ({ checked, onClick, testID }) => (
      <button data-testid={`${testID}-button`} onClick={onClick}>
        {checked.join(", ")}
      </button>
    ),
  } = props;
  // We are storing checked values in this ref
  // onChange is attached to Menu, which returns the value of the clicked item
  const checkedValues = useRef(new Set(value));
  const [menuProps, openMenu] = useMenu();

  // We are using this object to map values to names
  // It creates object in this format - `{ value: name }`
  const items = useMemo(
    () =>
      options.reduce<Record<string, string>>((acc, { name, value }) => {
        acc[value] = name;
        return acc;
      }, {}),
    [options]
  );

  // To pass to LabelComponent, we need to convert values to names
  // We need to pass array of names, not values
  const checked = useMemo(() => value.map((v) => items[v]).filter(Boolean), [value, items]);

  // This effect is necessary to make this component controlled
  // If value is changed from outside, we need to update the ref
  useEffect(() => {
    checkedValues.current = new Set(value);
  }, [value]);

  const handleChange = (value: T) => {
    const operation = checkedValues.current.has(value) ? "delete" : "add";
    checkedValues.current[operation](value);
    props.onChange(Array.from(checkedValues.current) as T[]);
  };

  // This wrapper is necessary to clear the ref when onClear is called
  const handleClear = onClear
    ? () => {
        checkedValues.current.clear();
        onClear();
      }
    : undefined;

  return (
    <>
      <MultiSelectLabelRoot className={disabled ? "disabled" : undefined}>
        {<LabelComponent testID={testID} checked={checked} disabled={disabled} title={title} onClick={!disabled ? openMenu : undefined} />}
      </MultiSelectLabelRoot>
      <Menu {...menuProps} keepMounted={false} autoFocus={false} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value as T)}>
        <MultiSelectContentWithRef<T> {...props} onClear={handleClear} />
      </Menu>
    </>
  );
};

export { MultiSelect };
