import { differenceInHours, format, subDays } from "date-fns";
import { IFilterItem } from "components/Filters/FilterBar/FilterBar.types";
import { UTCDate } from "./";
import { icon, PointExpression } from "leaflet";
import history from "config/history";
import { Timestamp } from "@shipin/proto-activity-client/google/protobuf/timestamp";
import { CSSProperties } from "react";
import { color, rgba } from "config/variable.styles";
import { OperationType, Severity, Policy, Policy_GlobalPolicy } from "@shipin/detector-client/detector";

export const roundMinutes = (minutes: number) => Math.ceil(minutes / 15) * 15;

export function sortFilterItem<T extends string = string>(
  arr?: Array<IFilterItem<T>>,
  options: {
    order: "asc" | "desc";
  } = { order: "asc" }
) {
  if (!arr) return [];
  return arr.slice().sort((a, b) => {
    const comparator = a.name?.localeCompare(b?.name);
    if (!comparator) return 0;
    if (options.order === "asc") {
      return comparator;
    }
    return -comparator;
  });
}

export const formatDuration = (value: number) => {
  return value < 10 ? `0${value}` : `${value}`;
};

// function to format the activity counts
export const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const getRoundedDates = () => {
  const formatter = "yyyy-MM-dd'T'HH:mm:ss";
  const endDate = UTCDate();
  const startDate = subDays(endDate, 1);

  /**
   * Rounding the time to the next 15 min step
   */
  const startMintues = roundMinutes(startDate.getMinutes());
  const endMintues = roundMinutes(endDate.getMinutes());

  startDate.setMinutes(startMintues, 0);
  endDate.setMinutes(endMintues, 0);

  return { startDate, endDate, parsedDate: { start: format(startDate, formatter), end: format(endDate, formatter) } };
};

/**
 * Accepts seconds and returns hours, minutes and seconds
 * @param seconds Time in seconds
 */
export const convertToHMS = (seconds: number) => ({
  hours: Math.floor(seconds / 3600),
  minutes: Math.floor((seconds % 3600) / 60),
  seconds: Math.floor((seconds % 3600) % 60),
});

/**
 * Accepts enabled in boolean and returns cache time accordingly
 * @param enabled in boolean
 */
export const getCacheTime = (enabled: boolean) => (enabled ? 5 * 60 * 1000 : 0);

/**
 * Accepts max and min in number and returns max-min / value accordingly
 * @param max  in number
 * @param min in number
 */
export const handleMinmax = (max: number | undefined, min: number | undefined) => {
  if (max === min) {
    return max;
  } else {
    return `${min ?? 0} - ${max ?? 0}`;
  }
};

/**
 * Accepts aisDate and activityDate in Date format and returns true/false
 * for AIS data updated in last 24 hours.
 * @param aisDate  in Date
 * @param activityDate in Date
 */
export const isAISUpdated = (aisDate: Date, activityDate: Date) => {
  return Math.abs(differenceInHours(activityDate, aisDate)) < 12;
};
/**
 * Accepts url and size in string and PointExpression formats
 * and returns url to create a new icon url.
 * @param url  in string
 * @param size in PointExpression
 */
export const createIcon = (url: string | undefined, size: PointExpression = [32, 32]) => (url ? icon({ iconUrl: url, iconSize: size }) : undefined);

/**
 * Takes the color code as index and returns the class name for tag to apply the css on tags
 */
export const tagColorsClassNames = ["default", "red", "orange", "yellow", "blue", "purple", "green", "grey"];

export const downloadFile = async (url: string | undefined, fileName: string) => {
  if (!url) return;
  try {
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (err) {
    console.error(err);
  }
};

export const clearQuery = () => {
  if (!window.location.search) {
    return;
  }
  history.push({
    pathname: window.location.pathname,
  });
};

const colorMap = {
  Alert: "#f05e5c",
  Attention: "#ffbc57",
  Routine: "#3cdaac",
} as const;

export const getColor = (severity: keyof typeof colorMap | undefined) => (severity ? colorMap[severity] : "#828382");

const protoColorMap = {
  [Severity.ALERT]: "#f05e5c",
  [Severity.ATTENTION]: "#ffbc57",
  [Severity.ROUTINE]: "#3cdaac",
} as const;

export const getProtoColor = (severity: Severity | undefined) => (severity ? protoColorMap[severity] : "#828382");

export const protoOperation = ["UNSPECIFIED", "CARGO", "MAINTENANCE", "BRIDGE", "SAFETY", "BUNKERING"];

export const protoSeverity = ["UNSPECIFIED", "Routine", "Attention", "Alert"];

const protoSeverityMap = {
  [Severity.ALERT]: "Alert",
  [Severity.ATTENTION]: "Attention",
  [Severity.ROUTINE]: "Routine",
} as const;

export const severityIndex = { Routine: 1, Attention: 2, Alert: 3 };

export const getprotoSeverityType = (severity: Severity | undefined) => (severity ? protoSeverityMap[severity] : "Routine");

export const convertToTimestamp = (date: Date | string): Timestamp => {
  // Adding "Z" to date strings makes sure JS treats them like they are in utc format
  // Z is Zulu - same as UTC
  const parsedDate = typeof date === "string" ? new Date(date.endsWith("Z") ? date : date + "Z") : date;

  return {
    seconds: BigInt(Math.floor(parsedDate.getTime() / 1000)),
    nanos: 0,
  };
};

export function convertFromTimestamp(timestamp: Timestamp): Date {
  return new Date(Number(timestamp.seconds) * 1000 + Number(timestamp.nanos) / 1000000);
}

export const adjustForUTCOffset = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
};

export const convertFromTimestampToUTC = (timestamp: Timestamp): Date => {
  return adjustForUTCOffset(convertFromTimestamp(timestamp));
};

export function operationTypeColor(operationType: OperationType): CSSProperties["backgroundColor"] {
  if (operationType === OperationType.CARGO) {
    return rgba(color.bgBrightTurquoise, 0.2);
  } else if (operationType === OperationType.SAFETY) {
    return rgba(color.bgHeliotrope, 0.2);
  } else if (operationType === OperationType.MAINTENANCE) {
    return rgba(color.bgYellow, 0.2);
  } else if (operationType === OperationType.BRIDGE) {
    return rgba(color.bgOrange, 0.2);
  } else if (operationType === OperationType.BUNKERING) {
    return rgba(color.bgBlue, 0.2);
  } else {
    throw Error("encountered unexpected operationType:" + operationType);
  }
}

export function operationTypeName(operationType: OperationType): string {
  if (operationType === OperationType.CARGO) {
    return "Cargo";
  } else if (operationType === OperationType.SAFETY) {
    return "Safety";
  } else if (operationType === OperationType.MAINTENANCE) {
    return "Maintenance";
  } else if (operationType === OperationType.BRIDGE) {
    return "Bridge";
  } else if (operationType === OperationType.BUNKERING) {
    return "Bunkering";
  } else {
    throw Error("encountered unexpected operationType:" + operationType);
  }
}

export function severityTypeColor(severityType: Severity): CSSProperties["backgroundColor"] {
  if (severityType === Severity.ROUTINE) {
    return getColor("Routine");
  } else if (severityType === Severity.ATTENTION) {
    return getColor("Attention");
  } else if (severityType === Severity.ALERT) {
    return getColor("Alert");
  } else {
    throw Error("encountered unexpected severityType:" + severityType);
  }
}

export function severityTypeName(severityType: Severity): string {
  if (severityType === Severity.ROUTINE) {
    return "Routine";
  } else if (severityType === Severity.ATTENTION) {
    return "Attention";
  } else if (severityType === Severity.ALERT) {
    return "Alert";
  } else {
    throw Error("encountered unexpected severityType:" + severityType);
  }
}

export function vesselPolicyData(vesselPolicy: Policy): { text: string; className: string } {
  if (vesselPolicy.variant.oneofKind === "global") {
    if (vesselPolicy.variant.global === Policy_GlobalPolicy.SHOW_ALL) {
      return { text: "Showing in all vessels", className: "showAllBtn" };
    }
    return { text: "Hiding in all vessels", className: "hideAllVesselsLabel" };
  }
  return { text: "Showing in some vessels", className: "showSomeVesselsLabel" };
}

export function convertToKebabCase(str: string) {
  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
}
