import { forwardRef, useState, ChangeEvent, useMemo } from "react";
import { IconButton, Input, MenuItem, InputBase, Checkbox, FormControlLabel } from "@material-ui/core";
import { FleetsDropdownStyle, FormControlStyle, DropdownSearchbox, AssociatedFleetLabelStyle, NoData } from "./InviteUsersDropdpwn.styles";
import { Search } from "@material-ui/icons";
import { FleetDropdpwnProps, CustomCheckboxProps, FleetLabelProps } from "./InviteUsersDropdpwn.types";
import Chip from "components/Chip";
import ChevronIcon from "components/Icon/ChevronIcon";
import GreyChevronIcon from "components/Icon/GreyChevronIcon";

const AssociatedFleetLabel = (props: FleetLabelProps) => {
  const { name } = props;

  return (
    <AssociatedFleetLabelStyle className="checkbox-label">
      <span className="label-text">{name}</span>
    </AssociatedFleetLabelStyle>
  );
};

const CustomCheckbox = forwardRef((props: CustomCheckboxProps, ref: any) => {
  const { name, id, checked, onChange } = props;

  return (
    <FormControlLabel
      value={id}
      checked={checked}
      ref={ref}
      onChange={(e: ChangeEvent<{ value?: string }>) => onChange(e.target.value)}
      control={<Checkbox data-testid={`checkbox-${name}`} name={name} color="primary" />}
      label={<AssociatedFleetLabel name={name} />}
    />
  );
});

// FleetList is used to render the list of fleets in the dropdown.
// We have co-located text state which filters the list into FleetsList
// When popover is closed, FleetsList is unmounted and text state is reset automatically.
// So, there is no need to reset the text state manually when popover is closed.
const FleetsList = (props: Pick<FleetDropdpwnProps, "label" | "items" | "onChange">) => {
  const { items, onChange, label = "Fleets" } = props;
  const [text, setText] = useState("");
  const sorted = useMemo(() => [...items]?.sort((a, b) => a.name.localeCompare(b.name)), [items]);
  const filtered = useMemo(() => sorted?.filter((el) => el.name.toLowerCase().includes(text.trim().toLowerCase())), [sorted, text]);

  const handleChange = (id?: string) => {
    if (!id) return;
    onChange?.((values) => values.map((e) => (e.id === id ? { ...e, selected: !e.selected } : e)));
  };

  return (
    <>
      <DropdownSearchbox onClickCapture={(e) => e.stopPropagation()} onKeyDownCapture={(e) => e.stopPropagation()}>
        <form className="search-group">
          <IconButton aria-label="search">
            <Search />
          </IconButton>
          <InputBase
            value={text}
            placeholder={`Search or Add ${label}`}
            inputProps={{ "aria-label": `Search or Add ${label}` }}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            data-testid={`search-${label.toLowerCase()}`}
          />
        </form>
      </DropdownSearchbox>
      <div className="list-auto-scroll">
        {filtered.length > 0 ? (
          filtered.map((data) => (
            <MenuItem key={data.id} value={data.id} className="width-checkbox" data-testid={`${label.toLowerCase()}-dropdown-item-${data.name}`}>
              <CustomCheckbox onChange={handleChange} id={data.id} name={data.name} checked={data.selected} />
            </MenuItem>
          ))
        ) : (
          <NoData>No results found</NoData>
        )}
      </div>
    </>
  );
};

const InviteUsersDropdpwn = (props: FleetDropdpwnProps) => {
  const { items, onClose, onChange, disabled, label = "Fleets" } = props;

  const handleDeleteChip = (name: string) => {
    onChange?.((values) => values.map((e) => (e.name === name ? { ...e, selected: false } : e)));
  };

  return (
    <FormControlStyle>
      <FleetsDropdownStyle
        data-testid={`${label.toLowerCase()}-dropdown`}
        labelId="associatedFleet"
        id={`associatedFleet`}
        multiple
        onClose={onClose}
        displayEmpty
        disabled={disabled}
        value={items.filter((i) => i.selected).map((e) => e.name)}
        IconComponent={disabled ? GreyChevronIcon : ChevronIcon}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected: any) =>
          selected.length === 0 ? (
            <span className="placeholder">Select{label === "none" ? "" : ` ${label}`}...</span>
          ) : (
            <>
              {selected.map((e: string, key: number) => (
                <Chip
                  data-testid={`chip-${e}`}
                  key={key}
                  enableDelete
                  onDeleteChip={() => handleDeleteChip(e)}
                  label={e}
                  style={{ borderRadius: "5%" }}
                />
              ))}
            </>
          )
        }
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          transformOrigin: { vertical: "top", horizontal: "right" },
          getContentAnchorEl: null,
          className: "custom-dropdown align-left popupFleetsDropdown",
          keepMounted: false,
        }}
      >
        <FleetsList items={items} onChange={onChange} label={label} />
      </FleetsDropdownStyle>
    </FormControlStyle>
  );
};

export default InviteUsersDropdpwn;
