import { ProgressBarContainer, ProgressFill } from "./ProgressBar.styles";

interface ProgressBarProps {
  value: number;
  testID: string;
  color: string;
}
const ProgressBar = (props: ProgressBarProps) => {
  const { value, testID, color } = props;

  return (
    <ProgressBarContainer className="progressbar" role="progressbar" data-testid={`${testID}-progress-track`}>
      <ProgressFill
        style={{
          width: `${value}%`,
          backgroundColor: color,
        }}
        data-testid={`${testID}-progress-fill`}
      />
    </ProgressBarContainer>
  );
};

export { ProgressBar };
