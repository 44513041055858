import { startOfMonth, startOfWeek, subDays, subMonths, endOfMonth } from "date-fns";

export const today = new Date();
export const firstDayOfWeek = startOfWeek(today);
export const firstDayOfLastWeek = subDays(firstDayOfWeek, 7);
export const previousMonth = subMonths(today, 1);
export const previousThreeMonths = subMonths(today, 3);
export const previousSixMonths = subMonths(today, 6);
export const previousYear = new Date().setFullYear(today.getFullYear() - 1);

const CURRENT_MONTH = {
  startDate: startOfMonth(today).toString(),
  endDate: endOfMonth(today).toString(),
};

const PREVIOUS_1M = {
  startDate: startOfMonth(previousMonth).toString(),
  endDate: endOfMonth(previousMonth).toString(),
};

const PREVIOUS_3M = {
  startDate: startOfMonth(previousThreeMonths).toString(),
  endDate: endOfMonth(previousMonth).toString(),
};

const PREVIOUS_6M = {
  startDate: startOfMonth(previousSixMonths).toString(),
  endDate: endOfMonth(previousMonth).toString(),
};

const PREVIOUS_12M = {
  startDate: startOfMonth(previousYear).toString(),
  endDate: endOfMonth(previousMonth).toString(),
};

export { CURRENT_MONTH, PREVIOUS_1M, PREVIOUS_3M, PREVIOUS_6M, PREVIOUS_12M };
