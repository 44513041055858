import { useAppDispatch, useFleets } from "hooks";
import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from "react";
import {
  setPerformanceAnalyticsFleetsFilter,
  setPerformanceAnalyticsVesselsFilter,
} from "services/redux/reducers/Filters/PerformanceAnalyticsFilter.slice";

const SingleViewContext = createContext<{ isSingleVesselView: boolean; vesselType: string | null } | null>(null);

const SingleViewProvider = (props: PropsWithChildren) => {
  const { data } = useFleets();
  const dispatch = useAppDispatch();

  const fleets = data?.fleets;

  const value = useMemo(() => {
    const isSingleVesselVisible = !!fleets && fleets.length === 1 && fleets?.[0]?.vessels.length === 1;
    if (isSingleVesselVisible) {
      const fleet = fleets?.[0];
      const vessel = fleet?.vessels?.[0];
      return { isSingleVesselView: isSingleVesselVisible, vesselType: vessel?.type?.toLowerCase() };
    }

    return { isSingleVesselView: isSingleVesselVisible, vesselType: null };
  }, [fleets]);

  useEffect(() => {
    if (!!fleets && fleets.length === 1 && fleets?.[0]?.vessels.length === 1) {
      const fleet = fleets?.[0];
      const vessel = fleet?.vessels?.[0];
      dispatch(setPerformanceAnalyticsFleetsFilter([fleet.id]));
      dispatch(setPerformanceAnalyticsVesselsFilter([vessel.id]));
    }
  }, [dispatch, fleets]);

  return <SingleViewContext.Provider value={value} {...props} />;
};

const useSingleVesselContext = () => {
  const context = useContext(SingleViewContext);
  if (context === null) {
    throw new Error("useSingleVesselContext must be used within a SingleViewProvider");
  }
  return context;
};

const useIsSingleView = () => useSingleVesselContext()?.isSingleVesselView;

const useSingleVesselType = () => useSingleVesselContext()?.vesselType;

export { SingleViewProvider, useIsSingleView, useSingleVesselType };
