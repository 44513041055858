import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import routes from "./routes";
import { autoRefreshQueries } from "./queryClient";
import { useAppDispatch, useDateRange } from "hooks";

import { autoRefresh } from "services/redux/reducers/RefreshTimer/RefreshTimer.slice";
import { reportsRoutes } from "pages/Reports/reports.routes";
import { userProfileRoutes } from "pages/MyProfile/userprofile.routes";

// List of routes that should not auto-refresh
const disableRefresh = [
  routes.detectors.path,
  routes.performanceAnalytics.path,
  routes.dashboardv2.path,
  ...Object.values(reportsRoutes).map((route) => route.path),
  ...Object.values(userProfileRoutes).map((route) => route.path),
];

// Co-locating refresh config effect into its own component since
// Subscriptions like useRouteMatch and useDateRange are not necessary
// at App level and will trigger unnecessary App level re-renders.
function RefreshConfig() {
  const {
    range: { live },
  } = useDateRange();
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (live && !disableRefresh.includes(pathname)) {
      const timer = setInterval(() => {
        dispatch(autoRefresh());
        autoRefreshQueries();
      }, 300000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [live, dispatch, client, pathname]);

  return null;
}

export { RefreshConfig };
