import { secondsToHours, secondsToMinutes, hoursToSeconds, minutesToSeconds, addMinutes, format } from "date-fns";

export * from "./ais";
export * from "./vessels";
export * from "./helpers";
export * from "./auth";
export * from "./validation";
export * from "./date";

export function toCapitalize(str: string | undefined) {
  if (!str) return;
  return str?.[0]?.toUpperCase() + str.slice(1);
}

export function isEmptyObject(obj: Object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function convertDuration(durationInSeconds?: number, options?: { short: boolean }) {
  if (durationInSeconds === undefined) return "-";
  let result: string;
  const hours = secondsToHours(durationInSeconds);
  const minutes = secondsToMinutes(durationInSeconds - hoursToSeconds(hours));
  const seconds = durationInSeconds - hoursToSeconds(hours) - minutesToSeconds(minutes);
  if (hours === 1) {
    result = "1 hour ago";
  } else if (minutes === 1) {
    result = "1 minute ago";
  } else if (seconds === 1) {
    result = "1 second ago";
  } else if (hours > 0 && hours < 24) {
    result = `${hours} hours ago`;
  } else if (hours === 24) {
    result = "1 day ago";
  } else if (hours > 24) {
    result = `${Math.round(hours / 24)} days ago`;
  } else if (minutes > 0) {
    result = `${minutes} minutes ago`;
  } else if (seconds > 0) {
    result = `${seconds} seconds ago`;
  } else {
    return options?.short ? "1s" : `Updated recently`;
  }
  return options?.short
    ? result
        .split(" ")
        .slice(0, 2)
        .map((e, i) => (i === 0 ? e : e[0]))
        .join("")
    : result;
}

/**
 * Converts date to UTC timezone
 * @param dateString Date in ISO format received from API.
 * Returns current date/time in UTC if dateString is not provided
 * @returns Date Object in UTC timezone.
 */
export function UTCDate(dateString?: string) {
  const date = !!dateString ? new Date(dateString) : new Date();
  return addMinutes(date, date.getTimezoneOffset());
}

export const toMinutes = (seconds?: number) => {
  if (!seconds) return undefined;
  const durationInMinutes = Number(seconds / 60);
  const minutes = durationInMinutes.toFixed(durationInMinutes > 1 ? 1 : 2);
  return minutes;
};

export const toHours = (seconds?: number) => {
  if (!seconds) return undefined;
  const durationInMinutes = Number(toMinutes(seconds));
  const durationInHours = Number(durationInMinutes / 60);
  const hours = durationInHours.toFixed(durationInHours > 1 ? 1 : 2);
  return hours;
};

export const formatDate = (date: string, pattern = "yyyy-MM-dd'T'00:00:00") => format(new Date(date), pattern);

export const isPreviewEnabled = window.__RUNTIME_CONFIG__.REACT_APP_ENABLE_PREVIEW === "1";

/**
 * Is the app running in Edge mode or Shore mode
 */
export const isEdge = window.__RUNTIME_CONFIG__.OPERATION_MODE === "edge";
export const today = new Date();
