import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { addLicense as am5addLicense } from "@amcharts/amcharts5";
import { addLicense } from "@amcharts/amcharts4/core";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import App from "./App";
import { GlobalStyles } from "./globalStyles";
import store from "./services/redux/store";
import { AMCHARTS4_LICENSE, AMCHARTS5_LICENSE, queryClient } from "config";

addLicense(AMCHARTS4_LICENSE);
am5addLicense(AMCHARTS5_LICENSE);

const rootElement = document.getElementById("root") as HTMLDivElement;
const persistor = persistStore(store);
const appRoot = ReactDOM.createRoot(rootElement);

appRoot.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
    <GlobalStyles />
  </QueryClientProvider>
);
