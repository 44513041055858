import { FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { rem } from "config/variable.styles";
import styled from "styled-components";
import { Checkbox } from "ui/Checkbox/Checkbox";
import { colors } from "ui/Theme/colors";

const FilterContent = styled.div`
  position: relative;
  padding: ${rem(16)} 0 ${rem(10)} 0;
  font-size: ${rem(14)};

  .close {
    position: absolute;
    top: ${rem(14)};
    right: ${rem(14)};
  }

  .selection {
    color: ${colors.primary[500]};
    font-size: ${rem(12)};
    padding: 0 ${rem(22)};
  }

  .input {
    margin: ${rem(10)} ${rem(21)} ${rem(5)};
    padding: ${rem(8)} ${rem(13)};
    border-radius: 8px;
    border: 1px solid ${colors.gray[300]};
    font-family: inherit;
    font-size: 12px;
    width: calc(100% - 40px);

    &:focus {
      outline: none;
      border-color: ${colors.primary[500]};
    }
  }
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  margin-top: ${rem(5)};

  max-height: ${rem(200)};
  overflow-y: auto;
  overflow-x: hidden;

  &.no-scroll {
    overflow-y: hidden;
    margin-top: ${rem(8)};
  }

  // customize scrollbar
  &::-webkit-scrollbar {
    width: ${rem(6)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: ${rem(3)};
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: ${rem(3)};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #666;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const CustomCheckbox = withStyles(
  {
    root: {
      marginLeft: 0,
      padding: "0 7px 0 0",
    },
  },
  {
    name: "custom-checkbox",
  }
)(Checkbox);

const FormControl = withStyles(
  {
    root: {
      // width: "100%",
      marginLeft: 20,
      "&.text": {
        innerHeight: rem(20),
      },
    },
    label: {
      fontSize: rem(14),
      lineHeight: rem(20),
    },
  },
  {
    name: "form-control",
  }
)(FormControlLabel);

const NoResults = styled.div`
  min-height: ${rem(200)};
  text-align: center;
  font-size: ${rem(14)};
  margin-top: ${rem(70)};
`;

export { FilterContent, CustomCheckbox, FormControl, FilterList, NoResults };
