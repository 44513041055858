import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PREVIOUS_3M } from "utils";

const initialState = {
  startDate: PREVIOUS_3M.startDate,
  endDate: PREVIOUS_3M.endDate,
};

const shoreDashboardDateFilter = createSlice({
  name: "date",
  initialState,
  reducers: {
    setShoreDashboardFilterDates(state, action: PayloadAction<{ startDate: string; endDate: string }>) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const { setShoreDashboardFilterDates } = shoreDashboardDateFilter.actions;
export default shoreDashboardDateFilter.reducer;
