import { useEffect } from "react";
import smartlook from "smartlook-client";
import { FullStory } from "@fullstory/browser";

import { isEdge } from "utils";
import { useCurrentUser } from "queries";

import { fetchFleets } from "services/redux/reducers/Fleets/Fleets.slice";
import { useAppDispatch } from "hooks";

const FullStoryConfig = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (!user || isEdge) return;

    const { id, username, full_name, email, role, designation = null, ship_manager = null, company = null } = user;
    dispatch(fetchFleets())
      .unwrap()
      .then(({ fleets }) => {
        smartlook.identify(id, {
          username,
          email,
          role,
          fleets: fleets.map((e) => e.name).join(","),
        });
        FullStory("setIdentity", {
          uid: id,
          properties: {
            email,
            domain: email.split("@")[1],
            fleets: fleets.map((e) => e.name).join(","),
            designation,
            ship_manager,
            company,
            full_name: full_name || username,
          },
        });
      });
  }, [user, dispatch]);

  return null;
};

export default FullStoryConfig;
