import { forwardRef, memo, useState } from "react";
import { format } from "date-fns";
import { Menu, MenuItem, TableCell, TableRow } from "@material-ui/core";

import { ChipGroup } from "ui";
import { useMenu } from "hooks";
import { useUsersQuery } from "queries";

import { useModal } from "components/Modal";
import MoreAction from "components/Icon/MoreAction";
import EditUser from "../EditUser/EditUser";
import { RBACRenderer } from "components/Auth";
import { ResetPasswordModal } from "../ResetPasswordModal/ResetPasswordModal";

// In this type, we are deriving Return type of single data item returned from useUsersQuery hook.
// So that changing anything in useUsersQuery will automatically update prop type here.
type UserTableRowProps = NonNullable<ReturnType<typeof useUsersQuery>[1]["data"]>["pages"][number]["items"][number] & {
  testID: string;
};

const UserTableRow = memo(
  forwardRef<HTMLTableRowElement, UserTableRowProps>((props, ref) => {
    const {
      email,
      role,
      source,
      testID,
      last_login,
      full_name,
      fleet_names,
      username,
      designation = null,
      ship_manager = null,
      company = null,
    } = props;
    const { modalProps, handleOpen } = useModal({ resetOnClose: true });
    const [menuProps, handleOpenMenu] = useMenu({ closeOnSelect: true });
    const isHighlighted = (email ?? "").toLowerCase().trim().endsWith("@shipin.ai");
    const [resetPasswordModal, setResetPasswordModal] = useState<"confirmationResetPwModal" | "showTempPassModal" | null>(null);
    const isOpen = resetPasswordModal !== null;

    return (
      <>
        <TableRow ref={ref} className={!!menuProps.open ? "row-hover" : ""} data-testid={testID}>
          <TableCell data-testid={`${testID}-email`} style={{ height: "56px" }} align="left" className={isHighlighted ? "highlight" : undefined}>
            {email}
          </TableCell>
          <TableCell data-testid={`${testID}-full-name`} align="left" style={{ height: "56px" }}>
            {full_name || "-"}
          </TableCell>
          <TableCell data-testid={`${testID}-designation`} align="left" style={{ height: "56px" }}>
            {designation || "-"}
          </TableCell>
          <TableCell data-testid={`${testID}-ship-manager`} align="left" style={{ height: "56px" }}>
            {ship_manager || "-"}
          </TableCell>
          <TableCell data-testid={`${testID}-company`} align="left" style={{ height: "56px" }}>
            {company || "-"}
          </TableCell>
          <TableCell data-testid={`${testID}-source`} align="left" style={{ height: "56px" }}>
            {source}{" "}
          </TableCell>
          <TableCell data-testid={`${testID}-role`} align="left" style={{ height: "56px" }}>
            {role}{" "}
          </TableCell>
          <TableCell data-testid={`${testID}-last-login`} align="left" style={{ height: "56px" }}>
            {last_login ? format(new Date(last_login), "yyyy MMM d, HH:mm") : "-"}{" "}
          </TableCell>
          <TableCell align="left" style={{ height: "56px" }}>
            <ChipGroup testID={`${testID}-fleets`} chips={fleet_names} visibleChips={3} tooltipAnchorCursor="default" />
          </TableCell>
          <RBACRenderer requiredPermissions={["can_modify_user_fleet_assoc"]}>
            <TableCell align="right" style={{ height: "56px" }} data-testid={`${testID}-actions`}>
              <MoreAction className="more-action" onClick={handleOpenMenu} />
            </TableCell>
          </RBACRenderer>
        </TableRow>
        <Menu {...menuProps} autoFocus={false}>
          <MenuItem onClick={handleOpen} data-testid="edit-user">
            Edit
          </MenuItem>
          {role?.toLowerCase() === "user" && (
            <MenuItem onClick={() => setResetPasswordModal("confirmationResetPwModal")} data-testid="reset-password">
              Reset Password
            </MenuItem>
          )}
        </Menu>
        {/* We are NOT unmounting Modal because, disabled save button flickers
        Due to internal implementation of useTransfer hook */}
        <EditUser {...modalProps} {...props} />
        {role?.toLowerCase() === "user" && isOpen && (
          <ResetPasswordModal
            isOpen
            onClose={() => setResetPasswordModal(null)}
            username={username}
            resetPasswordModal={resetPasswordModal}
            setResetPasswordModal={setResetPasswordModal}
          />
        )}
      </>
    );
  })
);

export { UserTableRow };
