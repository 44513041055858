import { Popover } from "@material-ui/core";
import { ChangeEvent, useCallback, useMemo, useRef, useState, memo } from "react";
import { FilterButtonNew } from "ui/FilterButton/FilterButton.new";
import { colors } from "ui/Theme/colors";
import { CustomCheckbox, FilterContent, FilterList, FormControl, NoResults } from "./FilterItem.styles";
import { UnstyledButton } from "ui/Button/Button.styles";
import { RxCross1 } from "react-icons/rx";
import { FilterItemProps, FilterItemValue } from "./FilterItem.types";
import { convertToKebabCase } from "utils";

const FilterCheckbox = memo(
  (
    props: FilterItemValue & {
      handleChange: (e: ChangeEvent<{}>, checked: boolean) => void;
      checked: boolean;
      testID?: string;
    }
  ) => {
    const { value, name, handleChange, checked, testID } = props;

    return (
      <FormControl
        data-testid={`${testID}-list-item-${value}`}
        checked={checked}
        className="text"
        name={value}
        label={name}
        control={<CustomCheckbox data-testid={`${testID}-checkbox-${value}`} />}
        onChange={handleChange}
      />
    );
  }
);

// search value should be cleared when the popover is closed.
// So, moving its state value to a separate component
// It will automatically clear the search value
// Because component will be unmounted when the popover is closed
const FilterListItems = (
  props: FilterItemProps & {
    minWidth: number;
    testID: string;
  }
) => {
  const { items, value, onChange, testID, minWidth, onClearAll, onSelectAll } = props;

  const onChangeRef = useRef(onChange);
  const [search, setSearch] = useState("");

  const hasCount = value.length > 0;

  const list = useMemo(() => {
    return [...items].sort((a, b) => a.name.localeCompare(b.name)).filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }, [items, search]);

  const activeValues = useMemo(() => {
    return items.reduce<Record<string, boolean>>((acc, item) => {
      acc[item.value] = value.includes(item.value);
      return acc;
    }, {});
  }, [items, value]);

  const handleChange = useCallback(
    (e: ChangeEvent<{}>, checked: boolean) => {
      const checkedItems = [...value];
      // @ts-ignore
      const name = e.target.name as string;

      if (checked) {
        checkedItems.push(name);
      } else {
        checkedItems.splice(checkedItems.indexOf(name), 1);
      }

      onChangeRef.current?.(checkedItems);
    },
    [value]
  );

  const handleSelectAll = () => {
    // Select All should select only filtered items through search
    const allValues = list.map((item) => item.value);
    onSelectAll?.(allValues);
  };

  return (
    <>
      <UnstyledButton data-testid={`${testID}-select-clear-all`} onClick={hasCount ? onClearAll : handleSelectAll} className="selection">
        {hasCount ? "Clear" : "Select"} all
      </UnstyledButton>
      {items.length > 2 && (
        <div>
          <input data-testid={`${testID}-search`} className="input" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      )}
      <FilterList className={list.length < 7 ? "no-scroll" : ""}>
        {!!list.length ? (
          list.map((item) => (
            <FilterCheckbox testID={testID} key={item.value} {...item} checked={activeValues[item.value]} handleChange={handleChange} />
          ))
        ) : (
          <NoResults data-testid={`${testID}-no-results`} style={{ minWidth }}>
            No results found
          </NoResults>
        )}
      </FilterList>
    </>
  );
};

const FilterItem = (props: FilterItemProps) => {
  const { title, value } = props;

  const minWidth = useRef<number>(200);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorElement);
  const testID = `${convertToKebabCase(title)}-filter`;

  // To set min width of No Results message container equal to the width of the popover
  // to prevent jumping in popover width when search results are empty.
  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      const width = node.querySelector(".filter-popover")?.clientWidth;
      if (width) {
        minWidth.current = width;
      }
    }
  }, []);

  return (
    <>
      <FilterButtonNew
        data-testid={`${testID}-button`}
        withArrow
        count={value.length}
        active={open}
        onClick={(e) => setAnchorElement(e.currentTarget)}
      >
        {title}
      </FilterButtonNew>
      <Popover
        ref={ref}
        open={open}
        anchorEl={anchorElement}
        data-testid={`${testID}-popover`}
        onClose={() => setAnchorElement(null)}
        PaperProps={{
          className: "filter-popover",
          style: {
            borderRadius: 10,
            backgroundColor: "#FAFAFA",
            border: `1px solid ${colors.secondary[100]}`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            marginTop: 8,
            minWidth: 200,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterContent>
          <UnstyledButton data-testid={`${testID}-close`} aria-label="Close" className="close" onClick={() => setAnchorElement(null)}>
            <RxCross1 size={14} />
          </UnstyledButton>
          <FilterListItems testID={testID} minWidth={minWidth.current} {...props} />
        </FilterContent>
      </Popover>
    </>
  );
};

export { FilterItem };
