import { CSSProperties } from "react";

interface Props {
  size?: number | string;
  color?: string;
  style?: CSSProperties;
  onClick?: (e: any) => void;
  className?: string;
  testID?: string;
}
const MoreAction = (props: Props) => {
  const { size = "1em", testID, color = "#000", style, onClick, className = "" } = props;

  return (
    <span data-testid={testID} className={`icon icon-more-action ${className}`.trim()} style={style} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <g fill={color} fillRule="nonzero" transform="translate(0 9)">
          <circle cx="3" cy="3" r="3"></circle>
          <circle cx="12" cy="3" r="3"></circle>
          <circle cx="21" cy="3" r="3"></circle>
        </g>
      </svg>
    </span>
  );
};

export default MoreAction;
