import { DetectorServiceClient } from "@shipin/detector-client/detector.client";
import { MediaServiceClient } from "@shipin/media-client/media.client";
import { ActivityServiceClient } from "@shipin/proto-activity-client/activity.client";
import { AnalyticsServiceClient } from "@shipin/analytics-client/analytics.client";
import { AnalyticsConfigServiceClient } from "@shipin/analytics-client/configuration.client";
import { UsersServiceClient } from "@shipin/users-client/service.client";
import { SensorsServiceClient } from "@shipin/sensors-client/service.client";
import { FleetServiceClient } from "@shipin/fleet-client/service.client";
import { NotifsServiceClient } from "@shipin/notifs-client/service.client";
import { DashboardRecentActivityServiceClient } from "@shipin/dashboard-client/recent_activity.client";
import { DashboardAnalyticsServiceClient } from "@shipin/dashboard-client/analytics.client";
import { transport } from "./api";

export const activityClient = new ActivityServiceClient(transport);
export const mediaClient = new MediaServiceClient(transport);
export const detectorClient = new DetectorServiceClient(transport);
export const analyticsClient = new AnalyticsServiceClient(transport);
export const userClient = new UsersServiceClient(transport);
export const sensorsClient = new SensorsServiceClient(transport);
export const fleetClient = new FleetServiceClient(transport);
export const notifsClient = new NotifsServiceClient(transport);
export const analyticsConfigClient = new AnalyticsConfigServiceClient(transport);
export const dashboardRecentActivityClient = new DashboardRecentActivityServiceClient(transport);
export const dashboardAnalyticsClient = new DashboardAnalyticsServiceClient(transport);
