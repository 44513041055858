interface CalendarProps {
  size?: string;
}
const Calendar = (props: CalendarProps) => {
  const { size = "16" } = props;

  return (
    <svg style={{ marginBottom: "1px" }} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1753_1775)">
        <path
          d="M2.07692 2.61536C1.7913 2.61536 1.51739 2.72882 1.31542 2.93078C1.11346 3.13274 1
          3.40666 1 3.69228V13.923C1 14.2086 1.11346 14.4826 1.31542 14.6845C1.51739 14.8865 1.7913
          15 2.07692 15H13.9231C14.2087 15 14.4826 14.8865 14.6846 14.6845C14.8865 14.4826 15 14.2086
          15 13.923V3.69228C15 3.40666 14.8865 3.13274 14.6846 2.93078C14.4826 2.72882 14.2087 2.61536 13.9231 2.61536H11.7692"
          stroke="#363636"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1 6.38464H15" stroke="#363636" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.23071 1V4.23077" stroke="#363636" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.7693 1V4.23077" stroke="#363636" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.23071 2.61536H9.61533" stroke="#363636" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1753_1775">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Calendar };
