import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface KPIConfigFilterState {
  fleet: string[];
  vessel: string[];
  kpi: string[];
  visibility: string[];
}

const initialState: KPIConfigFilterState = {
  fleet: [],
  vessel: [],
  kpi: [],
  visibility: [],
};

const kpiConfigFilterSlice = createSlice({
  name: "kpiConfigFilter",
  initialState,
  reducers: {
    setKPIConfigFleet(state, action: PayloadAction<string[]>) {
      state.fleet = action.payload;
    },
    setKPIConfigVessel(state, action: PayloadAction<string[]>) {
      state.vessel = action.payload;
    },
    setKPIConfigKpi(state, action: PayloadAction<string[]>) {
      state.kpi = action.payload;
    },
    setKPIConfigVisible(state, action: PayloadAction<string[]>) {
      state.visibility = action.payload;
    },
    removeFromKPIConfigVessel(state, action: PayloadAction<string[]>) {
      state.vessel = state.vessel.filter((vessel) => !action.payload.includes(vessel));
    },
    resetKPIConfigFilters(state) {
      state.fleet = [];
      state.vessel = [];
      state.kpi = [];
      state.visibility = [];
    },
  },
});

export const { setKPIConfigFleet, setKPIConfigVessel, removeFromKPIConfigVessel, setKPIConfigKpi, setKPIConfigVisible, resetKPIConfigFilters } =
  kpiConfigFilterSlice.actions;
export default kpiConfigFilterSlice.reducer;
