import { CustomModalProps, Modal } from "components/Modal";
import { ErrorToast } from "components/Toastbar";
import { useResetPasswordMutation } from "queries/user";
import { toast } from "react-toastify";

type ResetPasswordModalProps = {
  username: string | undefined;
  resetPasswordModal: "confirmationResetPwModal" | "showTempPassModal" | null;
  setResetPasswordModal: React.Dispatch<React.SetStateAction<"confirmationResetPwModal" | "showTempPassModal" | null>>;
};

const getSubtitle = (text1: string, text2: string) => (
  <p>
    <p style={{ marginBottom: "20px" }}>{text1}</p>
    <p>{text2}</p>
  </p>
);

export const ResetPasswordModal = (props: CustomModalProps & ResetPasswordModalProps) => {
  const mutation = useResetPasswordMutation();

  const onSubmitFunction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.username) return;

    props.resetPasswordModal === "confirmationResetPwModal" &&
      mutation.mutate(
        {
          userId: props.username,
        },
        {
          onSuccess: () => props.setResetPasswordModal("showTempPassModal"),
          onError: (e: any) => toast.error(<ErrorToast>{e.message ?? "Something went wrong!"}</ErrorToast>),
        }
      );

    props.resetPasswordModal === "showTempPassModal" && props.onClose?.(e);
  };

  return (
    <Modal
      {...props}
      size="small"
      subtitle={
        props.resetPasswordModal === "confirmationResetPwModal"
          ? getSubtitle(`You are about to reset the password for ${props.username ?? "-"}`, `Are you sure ?`)
          : getSubtitle(`Temporary password for ${props.username ?? "-"}:`, `${mutation.data?.temporaryPassword}`)
      }
      actionTitle={props.resetPasswordModal === "confirmationResetPwModal" ? "Reset Password" : "Okay"}
      hideCancel={props.resetPasswordModal === "showTempPassModal"}
      loading={mutation.isPending}
      onSubmit={(e) => onSubmitFunction(e)}
      submitButtonTestId="reset-password-submit"
    />
  );
};
