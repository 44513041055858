import {
  isToday as istoday,
  format,
  differenceInDays,
  subDays,
  addDays,
  endOfWeek,
  endOfMonth,
  startOfYear,
  startOfMonth,
  endOfYear,
  differenceInHours,
  isSameHour,
  addHours,
  isAfter,
  subSeconds,
} from "date-fns";
import { firstDayOfLastWeek, getRoundedDates, previousMonth, previousSixMonths, previousThreeMonths, previousYear, today, UTCDate } from "utils";
import { DefinedRange } from "materialui-daterange-picker";

/**
 * Helper function to parse startDate and endDate and return
 * common variables and functions to simplify logic.
 * @param start Start date from date picker
 * @param end End date from date picker
 */
export const parseDateRange = (start: string, end: string, isLive: boolean, isZoom = false) => {
  const pattern = "yyyy-MM-dd'T'00:00:00";
  const patternEnd = "yyyy-MM-dd'T'23:59:59";
  const startDate = new Date(format(new Date(start), pattern));
  const endDate = new Date(format(new Date(end), patternEnd));
  const isSameDaySelected = format(startDate, pattern) === format(endDate, pattern);
  const isSameMonth = isSameDaySelected ? false : format(startDate, "yyyy-MM") === format(endDate, "yyyy-MM");
  const isToday = isSameDaySelected && istoday(startDate);
  const isEndDayToday =
    isSameHour(new Date(format(new Date(end), "yyyy-MM-dd'T'HH:mm:ss")), new Date(format(UTCDate(), "yyyy-MM-dd'T'HH:mm:ss"))) ||
    isAfter(new Date(format(new Date(end), "yyyy-MM-dd'T'HH:mm:ss")), new Date(format(UTCDate(), "yyyy-MM-dd'T'HH:mm:ss"))) ||
    istoday(endDate);

  //two different functions for end and start dates as they are needed to rounded differently
  //startDate round it to past nearest 00 time.
  //endDate round it to next nearest 00 time.

  const startRoundMinutes = (minutes: number) => Math.floor(minutes / 15) * 15;
  const endRoundMinutes = (minutes: number) => Math.ceil(minutes / 15) * 15;

  const zoomPattern = "yyyy-MM-dd'T'HH:00:00";

  const startZoomDate = new Date(start);
  const endZoomDate = new Date(end);

  const startMintues = startRoundMinutes(startZoomDate.getMinutes());
  const endMintues = endRoundMinutes(endZoomDate.getMinutes());

  startZoomDate.setMinutes(startMintues, 0);
  endZoomDate.setMinutes(endMintues, 0);

  return {
    isSameDaySelected,
    isSameMonth,
    isToday,
    startDate,
    endDate,
    isEndDayToday,
    // Patterns to format date using date-fns
    pattern,
    patternEnd,
    getDateRange: () => {
      if (isZoom) {
        const currentDayPattern = "yyyy-MM-dd'T'HH:mm:ss";

        //to subtract one second from end date when it's 00:00:00 format and make it 23:59:59 after zoom
        const doSub = endZoomDate.getHours() === 0 && endZoomDate.getMinutes() === 0 && endZoomDate.getSeconds() === 0;

        const difference = differenceInHours(endZoomDate, startZoomDate);

        return {
          min: format(startZoomDate, currentDayPattern),
          max:
            difference < 2
              ? format(addHours(startZoomDate, 2), currentDayPattern)
              : doSub
              ? format(subSeconds(endZoomDate, 1), currentDayPattern)
              : format(endZoomDate, currentDayPattern),
        };
      }
      if (isLive) {
        const currentDayPattern = "yyyy-MM-dd'T'HH:mm:ss";
        const { startDate: startDateRounded, endDate: endDateRounded } = getRoundedDates();

        return {
          min: format(startDateRounded, currentDayPattern),
          max: format(endDateRounded, currentDayPattern),
        };
      }
      return {
        min: format(startDate, pattern),
        max: format(endDate, patternEnd),
      };
    },
    getText: () => {
      const isSameYear = isSameDaySelected || isSameMonth || format(startDate, "yyyy") === format(endDate, "yyyy");
      // If start and end date months are different, show both.
      const startDateFormatter = isSameDaySelected ? "d MMM yyyy" : isSameMonth ? "d" : `d MMM${isSameYear ? "" : " yyyy"}`;
      // If start and end date are equal, show only 1 date. otherwise show range.
      return `${format(startDate, startDateFormatter)}${isSameDaySelected ? "" : " - " + format(endDate, "d MMM yyyy")}`;
    },
    getDifference: () => differenceInDays(addDays(endDate, 1), startDate),
    getDiffInHours: () =>
      isZoom
        ? differenceInHours(new Date(format(new Date(endZoomDate), zoomPattern)), new Date(format(new Date(startZoomDate), zoomPattern)))
        : differenceInHours(endDate, startDate),
  };
};

// Overrides pre-defined ranges in the date range picker
export const getDefinedRanges = (): DefinedRange[] => {
  return [
    {
      label: "Last 7 Days",
      startDate: subDays(today, 6),
      endDate: today,
    },
    {
      label: "Last 30 Days",
      startDate: subDays(today, 29),
      endDate: today,
    },
    {
      label: "Previous Week",
      startDate: firstDayOfLastWeek,
      endDate: endOfWeek(firstDayOfLastWeek),
    },
    {
      label: "Previous Month",
      startDate: startOfMonth(previousMonth),
      endDate: endOfMonth(previousMonth),
    },
    {
      label: "Previous 3 Months",
      startDate: startOfMonth(previousThreeMonths),
      endDate: endOfMonth(previousMonth),
    },
    {
      label: "Previous 6 Months",
      startDate: startOfMonth(previousSixMonths),
      endDate: endOfMonth(previousMonth),
    },
    {
      label: "Previous Year",
      startDate: startOfYear(previousYear),
      endDate: endOfYear(previousYear),
    },
  ];
};
