import { SeverityLabelStyles } from "./Button.styles";

interface SeverityLabelProps {
  testID: string;
  variant: "alert" | "attention" | "routine";
}
const SeverityLabel = (props: SeverityLabelProps) => {
  const { variant, testID } = props;

  return (
    <SeverityLabelStyles className={variant} data-testid={`${testID}-${variant}`}>
      {variant}
    </SeverityLabelStyles>
  );
};

export { SeverityLabel };
