import { Modal as MuiModal } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Button from "components/Button";
import { rem } from "config/variable.styles";
import { CSSProperties } from "react";
import { ModalBase, ModalClose } from "./Modal.styles";
import { ModalProps } from "./Modal.types";

export const Modal = (props: ModalProps) => {
  const {
    children,
    size = "default",
    isOpen,
    onClose,
    title,
    subtitle,
    actionTitle,
    onSubmit,
    disableAction,
    loading,
    disableButton,
    className,
    hideCancel = false,
    submitButtonTestId,
  } = props;
  const style: CSSProperties = size === "small" ? { minHeight: "inherit", width: rem(667) } : {};

  return (
    <MuiModal open={isOpen} onClose={onClose} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
      <ModalBase onClick={(e) => e.stopPropagation()} className={className}>
        <div className="modal-wrap" style={style}>
          <ModalClose onClick={onClose}>
            <Close />
          </ModalClose>
          <div className="modal-header">
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
          {children}
          {!disableAction && (
            <div className="modal-footer">
              {!hideCancel && (
                <div className="foot-item" onClick={onClose}>
                  <Button variant="outlined" color="secondary" disabled={loading}>
                    Cancel
                  </Button>
                </div>
              )}
              <div className="foot-item">
                <Button
                  data-testid={submitButtonTestId}
                  variant="contained"
                  type="submit"
                  loading={loading}
                  onClick={onSubmit}
                  disabled={disableButton || loading}
                >
                  {actionTitle ?? title}
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalBase>
    </MuiModal>
  );
};
