import { Fragment, useRef, useState } from "react";
import { UserSortBy } from "@shipin/shipin-app-server-client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";

import { useUsersQuery } from "queries";

import { UserTableContainer, UserTableWrapper } from "./UserTable.styles";
import SortIcon from "components/Icon/SortIcon";
import TableNoDataPlaceholder from "components/TablePlaceholder/TablePlaceholder";
import { RBACRenderer } from "components/Auth";
import { UserTableRow } from "./UserTableRow";
import { UserTableLoader } from "./UserTableLoader";
import { rem } from "config/variable.styles";

const UserTable = () => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [order, setOrder] = useState<`${UserSortBy.order}`>("desc");
  const [sortBy, setSortBy] = useState<`${UserSortBy.sort}`>("last_login");
  const [ref, { data, isFetchingNextPage, isFetching, isError }] = useUsersQuery(order, sortBy);

  // To show table wide loader, checking if it is fetching first page and not fetching next page
  const isLoading = isFetching && !isFetchingNextPage;
  const noData = data?.pages?.[0].items?.length === 0 && !isLoading;

  const getHeadProps = (field: typeof sortBy) => ({
    IconComponent: SortIcon,
    active: field === sortBy,
    direction: field === sortBy ? order : "asc",
    onClick: () => {
      setSortBy(field);
      setOrder(order === "asc" ? "desc" : "asc");

      // Disabling automatic scroll restoration
      // manually scroll to top when user changes sort order
      window.scrollTo(0, 0);
      tableRef.current?.scrollTo({ top: 0 });
    },
  });

  const tableWrapperScroll = (e: any) => {
    const scrolledFromTop = e.currentTarget.scrollTop;
    setIsScrolled(scrolledFromTop > 10);
  };

  const renderLoader = () => (
    <>
      <UserTableLoader />
      <UserTableLoader />
      <UserTableLoader />
    </>
  );

  const getStyle = (width: number) => ({
    width: rem(width),
    minWidth: rem(width),
    maxWidth: rem(width),
  });

  return (
    <UserTableWrapper>
      <UserTableContainer onScroll={tableWrapperScroll} ref={tableRef}>
        <TableContainer component={Paper} className={isScrolled ? "isSticky" : ""}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={getStyle(300)} sortDirection={sortBy === "email" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-email" {...getHeadProps("email")}>
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(180)} sortDirection={sortBy === "full_name" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-name" {...getHeadProps("full_name")}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(180)} sortDirection={sortBy === "designation" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-designation" {...getHeadProps("designation")}>
                    Designation
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(180)} sortDirection={sortBy === "ship_manager" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-ship-manager" {...getHeadProps("ship_manager")}>
                    Ship Manager
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(180)} sortDirection={sortBy === "company" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-company" {...getHeadProps("company")}>
                    Company
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(123)}>
                  <TableSortLabel data-testid="user-table-header-source" IconComponent={SortIcon} disabled>
                    Source
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(140)} sortDirection={sortBy === "role" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-role" {...getHeadProps("role")}>
                    Role
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={getStyle(200)} sortDirection={sortBy === "last_login" ? order : false}>
                  <TableSortLabel data-testid="user-table-header-last-login" {...getHeadProps("last_login")}>
                    Last login
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel data-testid="user-table-header-associated-fleets" {...getHeadProps("email")} disabled>
                    Associated Fleets
                  </TableSortLabel>
                </TableCell>
                <RBACRenderer requiredPermissions={["can_modify_user_fleet_assoc"]}>
                  <TableCell align="right" style={getStyle(93)} />
                </RBACRenderer>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && !isError
                ? renderLoader()
                : data?.pages.map((page, i) => (
                    <Fragment key={i}>
                      {page.items.map((row, j) => (
                        <UserTableRow
                          {...row}
                          key={row.id}
                          testID={`user-table-row-${i}-${j}`}
                          ref={i === data.pages.length - 1 && j === page.items.length - 1 ? ref : undefined}
                        />
                      ))}
                    </Fragment>
                  ))}
              {isFetchingNextPage && renderLoader()}
            </TableBody>
          </Table>
          {/* Adding isSuccess here won't work well with refresh button
        refresh invalidatesQuery, where isSuccess remains true */}
          {(noData || isError) && <TableNoDataPlaceholder isError={isError} />}
        </TableContainer>
      </UserTableContainer>
    </UserTableWrapper>
  );
};

export default UserTable;
