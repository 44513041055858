/* eslint-disable max-lines */
import { useCallback } from "react";
import { InfiniteData, keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ActivitiesFilter,
  ActivitiesFilter_ReviewedFilter,
  Activity,
  GetActivitiesRequest,
  GetActivitiesRequest_Sort,
  GetActivitiesResponse_ExtendedActivity,
  GetActivityStatsByFleetResponse,
  GetActivityStatsByVesselResponse,
  GetActivityTimelineResponse,
  StarActivityRequest,
  StarActivityResponse,
  Tag,
  UpdateWorkflowRequest,
  UpdateWorkflowResponse,
} from "@shipin/proto-activity-client/activity";
import { FleetActivitySummary, VesselActivitySummary } from "@shipin/shipin-app-server-client";
import { format } from "date-fns";

import { convertFromTimestamp, convertToTimestamp } from "utils";
import { useAppDispatch, useAppSelector, useDateRange, useFleetsfromVessel, useObserver } from "hooks";

import { usePermissions } from "./user";
import { protoFilterKey, useProtoFilters } from "../hooks/useFilters";
import { activityClient } from "../config/clients";
import { useActivityIDContext } from "context/ActivityIDContext";
import { addTag, removeTag, setActivityStared, setActivityWorkflowStatus, updateTag } from "services/redux/reducers/Activities/activityDetails.slice";
import { getOptions } from "components/Timelines/utils";
import { TimelineAmchartObject } from "types/EventTimeline.types";
import { activityType, severity, severityColors } from "constants/translations";

const transformByFleet = (data: GetActivityStatsByFleetResponse): FleetActivitySummary[] => {
  return data.fleetStats.map((item) => {
    return {
      fleet_id: item.fleetId,
      summary: item.stats.reduce<FleetActivitySummary["summary"]>(
        (acc, stat) => {
          if (!stat.count) return acc;

          return {
            ...acc,
            [severity[stat.severity]]: Number(stat.count),
          };
        },
        {
          Routine: undefined,
          Attention: undefined,
          Alert: undefined,
        }
      ),
    };
  });
};

const transformByVessel = (data: GetActivityStatsByVesselResponse): VesselActivitySummary[] => {
  return data.vesselStats.map((item) => {
    return {
      vessel_id: item.vesselId,
      summary: item.stats.reduce<VesselActivitySummary["summary"]>((acc, stat) => {
        if (!stat.count) return acc;

        return {
          ...acc,
          [severity[stat.severity]]: Number(stat.count),
        };
      }, {}),
    };
  });
};

function useActivityByVesselQuery() {
  const filter = useProtoFilters();
  const { vesselIds, ...rest } = filter;

  return useQuery({
    queryKey: protoFilterKey("by-dashboard-vessel", { ...rest, vesselIds: [] }),
    queryFn: () => activityClient.getActivityStatsByVessel({ filter: { ...rest, vesselIds: [] } }).response,
    placeholderData: keepPreviousData,
    select: transformByVessel,
  });
}

function useProtoFiltersQuery() {
  const filter = useProtoFilters();
  const camerasVisible = useAppSelector((state) => state.tableFilterBar.activeFilters.camera);

  return useQuery({
    queryKey: protoFilterKey("options", filter, camerasVisible),
    queryFn: () => activityClient.getFilterOptionsFacets({ filter, includeCameras: camerasVisible }).response,
    placeholderData: keepPreviousData,
  });
}

function useTagsFilterOptions() {
  const filter = useProtoFilters();
  const tagFilterSwitch = useAppSelector((state) => state.tagSwitch);
  const camerasVisible = useAppSelector((state) => state.tableFilterBar.activeFilters.camera);

  const filters: ActivitiesFilter = {
    ...filter,
    startTimestamp: tagFilterSwitch ? convertToTimestamp("2023-01-01T00:00:00") : filter.startTimestamp,
    endTimestamp: tagFilterSwitch ? convertToTimestamp(format(new Date(), "yyyy-MM-dd'T'23:59:59")) : filter.endTimestamp,
  };

  return useQuery({
    queryKey: protoFilterKey("tag-options", filters, camerasVisible),
    queryFn: () => activityClient.getFilterOptionsFacets({ filter: filters, includeCameras: camerasVisible }).response,
    placeholderData: keepPreviousData,
  });
}

function useBlacklistStatus() {
  const permissions = usePermissions();
  const isHidingAllowed = permissions?.can_hide_activities;
  const isHiddenActivityVisible = permissions?.can_view_hidden_activities;
  const blacklist = useAppSelector((state) => state.activityDetails.data?.blacklist);
  const blacklistComment = useAppSelector((state) => state.activityDetails.data?.blacklistComment);

  return {
    blacklist,
    blacklistComment,
    isHidingAllowed,
    isHiddenActivityVisible,
  };
}

function useInvalidateFilters() {
  const client = useQueryClient();

  return () => {
    client.invalidateQueries({ queryKey: ["options"] });
    client.invalidateQueries({ queryKey: ["tag-options"] });
  };
}

function useSummaryByFleetQuery() {
  const filter = useProtoFilters();

  return useQuery({
    queryKey: protoFilterKey("by-fleet", filter),
    queryFn: () => activityClient.getActivityStatsByFleet({ filter }).response,
    staleTime: 0,
    select: transformByFleet,
  });
}

function useSummaryByVesselQuery() {
  const filter = useProtoFilters();

  return useQuery({
    queryKey: protoFilterKey("by-vessel", filter),
    queryFn: () => activityClient.getActivityStatsByVessel({ filter }).response,
    staleTime: 0,
    select: transformByVessel,
  });
}

const fetchActivities = async (
  request: GetActivitiesRequest
): Promise<{
  nextPageParam: number | undefined;
  activities: GetActivitiesResponse_ExtendedActivity[];
}> => {
  const { activities = [] } = await activityClient.getActivities(request).response;

  if (!activities.length || activities.length < request.count) {
    return {
      nextPageParam: undefined,
      activities,
    };
  }

  return {
    nextPageParam: request.offset + request.count,
    activities,
  };
};

type QueryResult = Awaited<ReturnType<typeof fetchActivities>>;

const selectActivity = (response: InfiniteData<QueryResult>): QueryResult => ({
  nextPageParam: response.pages[response.pages.length - 1].nextPageParam,
  activities: response.pages.flatMap((page) => page.activities),
});

const ACTIVITY_LIST_KEY = "activities-list";

/**
 * Custom hook to invalidate the activities list and update the tags associated with an activity.
 * Activity list have infinite data, so we need to update the tags in the cache instead of refetching the data.
 * @returns An object with two functions: addTag and removeTag.
 */
function useInvalidateTagsForActivities() {
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const activity_id = useActivityIDContext();

  return {
    addTag: (tag: Tag) => {
      client.setQueriesData<InfiniteData<QueryResult>>({ queryKey: [ACTIVITY_LIST_KEY] }, (old) => {
        if (!old) return old;
        return {
          pages: old.pages.map((page) => ({
            ...page,
            activities: page.activities.map((activity) => {
              if (activity.activity?.id === activity_id) {
                return {
                  ...activity,
                  tags: [...activity.tags, tag].sort((a, b) => a?.name.localeCompare(b?.name)),
                };
              }
              return activity;
            }),
          })),
          pageParams: old.pageParams,
        };
      });
      dispatch(addTag(tag));
    },
    removeTag: (tagId: string) => {
      client.setQueriesData<InfiniteData<QueryResult>>({ queryKey: [ACTIVITY_LIST_KEY] }, (old) => {
        if (!old) return old;
        return {
          pages: old.pages.map((page) => ({
            ...page,
            activities: page.activities.map((activity) => {
              if (activity.activity?.id === activity_id) {
                return {
                  ...activity,
                  tags: activity.tags.filter((tag) => tag.tagId !== tagId),
                };
              }
              return activity;
            }),
          })),
          pageParams: old.pageParams,
        };
      });
      dispatch(removeTag(tagId));
    },
    updateTag: (tag: Tag) => {
      client.setQueriesData<InfiniteData<QueryResult>>({ queryKey: [ACTIVITY_LIST_KEY] }, (old) => {
        if (!old) return old;
        return {
          pages: old.pages.map((page) => ({
            ...page,
            activities: page.activities.map((activity) => {
              if (!activity.tags || !activity.tags.length) {
                return activity;
              }
              return {
                ...activity,
                tags: activity.tags.map((t) => (t.tagId === tag.tagId ? tag : t)),
              };
            }),
          })),
          pageParams: old.pageParams,
        };
      });
      dispatch(updateTag(tag));
    },
  };
}

function useActivityListQuery(sort: GetActivitiesRequest_Sort) {
  const filters = useProtoFilters();
  const { loading } = useFleetsfromVessel();
  const tagFilterSwitch = useAppSelector((state) => state.tagSwitch);
  const { reviewed, starred, workflowStatus } = useAppSelector((state) => state.activityListFilter);

  const reviewedFilter: ActivitiesFilter_ReviewedFilter | undefined = !reviewed
    ? undefined
    : {
        value: reviewed === "reviwed",
      };

  // If starred is false, list will show all activities - both starred and unstarred
  // So, we don't need to pass starred filter in that case
  // false is just used to indicate checkbox state
  const starredFilter = starred ? { value: true } : undefined;

  const key = protoFilterKey(ACTIVITY_LIST_KEY, { ...filters, reviewed: reviewedFilter, stared: starredFilter, workflowStatuses: workflowStatus });

  const query = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: [...key, sort, tagFilterSwitch],
    queryFn: ({ pageParam }) => {
      return fetchActivities({
        count: 10,
        offset: pageParam,
        filter: {
          ...filters,
          reviewed: reviewedFilter,
          stared: starredFilter,
          workflowStatuses: workflowStatus,
          startTimestamp: tagFilterSwitch ? convertToTimestamp("2023-01-01T00:00:00") : filters.startTimestamp,
          endTimestamp: tagFilterSwitch ? convertToTimestamp(format(new Date(), "yyyy-MM-dd'T'23:59:59")) : filters.endTimestamp,
        },
        includeBeaufortScale: true,
        includeTags: true,
        sorts: [sort],
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextPageParam,
    staleTime: 0,
    gcTime: 0,
    select: selectActivity,
  });

  const ref = useObserver({
    onIntersect: () => query.fetchNextPage(),
  });

  return [ref, { ...query, isLoading: loading || query.isLoading, isFetching: loading || query.isFetching }] as const;
}

export const TIMELINE_KEY = "activity-timeline";

function selectDataForTimeline(data: GetActivityTimelineResponse): TimelineAmchartObject[] {
  if (!data?.timelineEntries || !data.timelineEntries.length) return [];

  const result = data.timelineEntries.reduce<Record<string, TimelineAmchartObject>>((acc, item, i) => {
    if (!item.rangeStart || !item.rangeEnd) return acc;

    const { rangeStart, rangeEnd } = item;
    const from = convertFromTimestamp(rangeStart);
    const to = convertFromTimestamp(rangeEnd);
    const from_dttm = from?.toISOString();
    const to_dttm = to?.toISOString();
    const severityValue = severity[item.severity];
    const key = `${from_dttm}-${severityValue}`;
    const activityValue = activityType[item.operationType];
    const count = Number(item.count);

    if (!acc[key]) {
      acc[key] = {
        id: `${severityValue}-${i}`,
        type: severityValue,
        amc_fromDate: from,
        amc_toDate: to,
        color: severityColors[severityValue],
        from_dttm,
        to_dttm,
        counts: {
          [activityValue]: count,
        },
      };
      return acc;
    }

    acc[key] = {
      ...acc[key],
      counts: {
        ...acc[key].counts,
        [activityValue]: count,
      },
    };

    return acc;
  }, {});

  return Object.values(result);
}

function useActivityTimelineQuery(vessel: string | undefined) {
  const { vesselIds, ...filter } = useProtoFilters();
  const { getDiffInHours } = useDateRange();

  const { data, isFetching } = useQuery({
    queryKey: protoFilterKey(TIMELINE_KEY, {
      ...filter,
      vesselIds: vessel ? [vessel] : [],
    }),
    queryFn: async () => {
      const diffInHours = getDiffInHours();
      const { resolution } = getOptions(diffInHours);

      // Replace start_dttm with timeline_range
      if (!vessel) return Promise.reject("Err");
      return activityClient.getActivityTimeline({
        filter: {
          ...filter,
          vesselIds: vessel ? [vessel] : [],
        },
        resolution: resolution.minutes || resolution.hours * 60,
      }).response;
    },
    // Chart is disposed on cleanup. So, here we are
    // Disabling structural sharing becuase invalidating timeline on Refresh click
    // Will not trigger re-creation of chart if data remains referencially same.
    structuralSharing: false,
    enabled: !!vessel,
    select: selectDataForTimeline,
  });

  return {
    data,
    isFetching,
  };
}

function useActivitySummary() {
  const filter = useProtoFilters();

  return useQuery({
    queryKey: protoFilterKey("stats", filter),
    queryFn: () => activityClient.getActivityStats({ filter }).response,
  });
}

function useActivityIDListQuery() {
  const filter = useProtoFilters();
  const { data: summary } = useActivitySummary();
  const totalActivities = (summary?.stats ?? []).reduce((acc, item) => acc + Number(item.count), 0);

  const query = useQuery({
    queryKey: protoFilterKey("activity-id-list", filter),
    queryFn: () =>
      activityClient.getActivityIds({
        filter,
        count: 1000,
        start: "",
      }).response,

    staleTime: Infinity,
    gcTime: Infinity,
    select: (response) => response?.activityIds ?? [],
    enabled: totalActivities <= 1000,
  });

  return {
    ...query,
    totalActivities,
  };
}

type InvalidateActivityListOptions = {
  id: string;
  activity: Partial<Omit<Activity, "id">>;
};

function useInvalidateActivityList() {
  const client = useQueryClient();

  return useCallback(
    (options: InvalidateActivityListOptions) => {
      client.setQueriesData<InfiniteData<QueryResult>>({ queryKey: [ACTIVITY_LIST_KEY] }, (old) => {
        if (!old) return old;

        return {
          pages: old.pages.map((page) => ({
            ...page,
            activities: page.activities.map((item) => {
              if (item.activity?.id === options.id) {
                return {
                  ...item,
                  activity: {
                    ...item.activity,
                    ...options.activity,
                  },
                };
              }
              return item;
            }),
          })),
          pageParams: old.pageParams,
        };
      });
    },
    [client]
  );
}

/**
 * When any change is made to an activity - changing tags, adding comment
 * We need to mark it as unreviewed so that it appears in the list of activities to review
 */
function useMarkUnreviewed(activityID: string) {
  const updateList = useInvalidateActivityList();

  return () => {
    updateList({
      id: activityID,
      activity: {
        reviewed: false,
      },
    });
  };
}

function useStarActivityMutation() {
  const dispatch = useAppDispatch();
  const updateActivity = useInvalidateActivityList();

  return useMutation<StarActivityResponse, any, StarActivityRequest>({
    mutationFn: (req) => activityClient.starActivity(req).response,
    onSuccess: (response, variables) => {
      dispatch(setActivityStared(response.star));
      updateActivity({
        id: variables.activityId,
        activity: {
          stared: response.star,
        },
      });
    },
  });
}

function useUpdateActivityWorkflowStatusMutation() {
  const dispatch = useAppDispatch();
  const updateList = useInvalidateActivityList();

  return useMutation<UpdateWorkflowResponse, any, UpdateWorkflowRequest>({
    mutationFn: (req) => activityClient.updateWorkflow(req).response,
    onSuccess: (response, variables) => {
      updateList({
        id: variables.activityId,
        activity: {
          workflowStatus: response.status,
        },
      });
      dispatch(setActivityWorkflowStatus(response.status));
    },
  });
}

export {
  useActivityByVesselQuery,
  useActivityListQuery,
  useProtoFiltersQuery,
  useBlacklistStatus,
  useSummaryByFleetQuery,
  useSummaryByVesselQuery,
  useInvalidateFilters,
  useInvalidateTagsForActivities,
  useActivityTimelineQuery,
  useActivityIDListQuery,
  useActivitySummary,
  useStarActivityMutation,
  useInvalidateActivityList,
  useMarkUnreviewed,
  useUpdateActivityWorkflowStatusMutation,
  useTagsFilterOptions,
};
