import { Menu, MenuItem } from "@material-ui/core";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import DownArrow from "components/Icon/DownArrow";
import useMenu from "hooks/useMenu";
import { HeaderCrumb } from "./Header.types";
import { convertToKebabCase, isEdge } from "utils";

//Header Submenu
const Submenu = (props: Omit<Required<HeaderCrumb>, "exact" | "visible" | "path">) => {
  const { label, submenu } = props;
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [menuProps, handleOpen] = useMenu({ closeOnSelect: true, className: "submenu-dropdown" });
  const active = submenu.some((sub) => sub.sublinkpath === pathname || (!!sub.nestedRouteBasePath ? sub.nestedRouteBasePath === match.path : false));

  const labelLowerCase = label.toLowerCase();

  if (isEdge) return null;

  return (
    <>
      <li className="nav__item" data-testid={labelLowerCase}>
        <span className={`nav__item_link ${!!menuProps.open || active ? "nav__item_link_active" : ""}`} color="inherit" onClick={handleOpen}>
          {label}
          <span className="downArrow">
            <DownArrow />
          </span>
        </span>
      </li>
      <Menu {...menuProps} autoFocus={false}>
        {submenu.map((sub, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (sub.sublinkpath) history.push(sub.sublinkpath);
            }}
            selected={sub.sublinkpath === pathname || (!!sub.nestedRouteBasePath ? sub.nestedRouteBasePath === match.path : false)}
            disabled={sub.disabled}
            data-testid={`${labelLowerCase}-${convertToKebabCase(sub.sublabel)}`}
          >
            {sub.sublabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Submenu;
