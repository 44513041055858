import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BenchmarkType, GroupBy, ScoreGroupName } from "@shipin/analytics-client/analytics";
import { PREVIOUS_3M } from "utils";

export const OVERALL_SCORE_STATE = {
  score: "All",
  type: "group",
  name: "Overall Performance Score",
} as const;

export const performanceAnalyticsFilterInitialState: {
  dateRange: {
    start: string;
    end: string;
  };
  fleet: string[];
  vessel: string[];
  benchmark: BenchmarkType[];
  scoreCard:
    | {
        score: ScoreGroupName | "All";
        type: "group";
        name: string;
      }
    | {
        kpiId: number;
        type: "kpi";
        name: string;
      };
  vesselType: string[];
  // omitting UNSPECIFIED
  groupBy: GroupBy.FLEET | GroupBy.VESSEL_TYPE;
  showAverageLine: boolean;
} = {
  dateRange: {
    start: PREVIOUS_3M.startDate,
    end: PREVIOUS_3M.endDate,
  },
  fleet: [],
  vessel: [],
  benchmark: [BenchmarkType.P25, BenchmarkType.P75],
  scoreCard: OVERALL_SCORE_STATE,
  vesselType: [],
  groupBy: GroupBy.FLEET,
  showAverageLine: false,
};

const performanceAnalyticsFilter = createSlice({
  name: "performanceAnalyticsFilter",
  initialState: performanceAnalyticsFilterInitialState,
  reducers: {
    setPerformanceAnalyticsDateRange(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setPerformanceAnalyticsFleetsFilter(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.fleet>) {
      state.fleet = action.payload;
    },
    setPerformanceAnalyticsVesselsFilter(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.vessel>) {
      state.vessel = action.payload;
    },
    setPerformanceAnalyticsBenchmark(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.benchmark>) {
      state.benchmark = action.payload;
    },
    setPerformanceAnalyticsScoreCard(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.scoreCard>) {
      state.scoreCard = action.payload;
    },
    setVesselType(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.vesselType>) {
      state.vesselType = action.payload;
    },
    setGroupBy(state, action: PayloadAction<typeof performanceAnalyticsFilterInitialState.groupBy>) {
      state.groupBy = action.payload;
    },
    setShowAverageLine(state, action: PayloadAction<boolean>) {
      if (state.showAverageLine === action.payload) return;
      state.showAverageLine = action.payload;
    },
    removeFromFleetandVessel(state, action: PayloadAction<{ fleet: string[]; vessel: string[] }>) {
      if (!!action.payload.vessel.length) {
        state.vessel = state.vessel.filter((vessel) => !action.payload.vessel.includes(vessel));
      }
      if (!!action.payload.fleet.length) {
        state.fleet = state.fleet.filter((fleet) => !action.payload.fleet.includes(fleet));
      }
    },
    removeFromVesselType(state, action: PayloadAction<string[]>) {
      state.vesselType = state.vesselType.filter((vesselType) => !action.payload.includes(vesselType));
    },
    clearPerformanceAnalyticsFilter(state) {
      state.benchmark = performanceAnalyticsFilterInitialState.benchmark;
      state.fleet = performanceAnalyticsFilterInitialState.fleet;
      state.showAverageLine = performanceAnalyticsFilterInitialState.showAverageLine;
      state.vessel = performanceAnalyticsFilterInitialState.vessel;
      state.vesselType = performanceAnalyticsFilterInitialState.vesselType;
    },
  },
});

export const {
  setPerformanceAnalyticsDateRange,
  setPerformanceAnalyticsFleetsFilter,
  setPerformanceAnalyticsVesselsFilter,
  setPerformanceAnalyticsBenchmark,
  setPerformanceAnalyticsScoreCard,
  setVesselType,
  setGroupBy,
  setShowAverageLine,
  removeFromFleetandVessel,
  removeFromVesselType,
  clearPerformanceAnalyticsFilter,
} = performanceAnalyticsFilter.actions;
export default performanceAnalyticsFilter.reducer;
