const ArrowUp = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 1L4 7" stroke="black" strokeLinecap="round" />
    <path d="M7 4L4 1L1 4" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowDown = () => (
  <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 7L3.5 1" stroke="black" strokeLinecap="round" />
    <path d="M0.5 4L3.5 7L6.5 4" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export { ArrowUp, ArrowDown };
