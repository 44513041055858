import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  GetActivityRequest,
  Activity,
  GetActivityResponse_WeatherStats,
  Tag,
  GetActivityResponse_AisData,
  GetActivityResponse_AisData_Port,
  WorkflowStatus,
} from "@shipin/proto-activity-client/activity";

import { ERROR_MESSAGE } from "constants/api/common";
import { ReduxState } from "types/ReduxState/common.types";
import { activityClient } from "config/clients";
import { convertFromTimestamp, UTCDate } from "utils";

type Port = Partial<Omit<GetActivityResponse_AisData_Port, "time">> & {
  time: string | undefined;
};

interface ActivityDetails extends Omit<Activity, "startTimestamp" | "endTimestamp"> {
  start_dttm: string | undefined;
  end_dttm: string | undefined;
  mediaStart: string | undefined;
  mediaEnd: string | undefined;
  weather: Omit<GetActivityResponse_WeatherStats, "firstReading" | "lastReading"> | undefined;
  tags: Tag[];
  ais:
    | Partial<
        Omit<GetActivityResponse_AisData, "date" | "lastPort" | "nextPort"> & {
          timestamp: string | undefined;
          lastPort: Port | undefined;
          nextPort: Port | undefined;
        }
      >
    | undefined;
}

const initialState: ReduxState<ActivityDetails | null> = {
  status: "idle",
  data: null,
  error: null,
};

export const fetchActivityDetails = createAsyncThunk("activityDetails/fetch", async (payload: GetActivityRequest, thunkAPI) => {
  try {
    const response = await activityClient.getActivity(payload).response;
    if (!!response?.activity) {
      const { startTimestamp, endTimestamp, ...rest } = response.activity;

      delete response.weather?.firstReading;
      delete response.weather?.lastReading;

      const nextPort = {
        unlcode: response.firstAis?.nextPort?.unlcode,
        name: response.firstAis?.nextPort?.name,
        time: response.firstAis?.nextPort?.time
          ? UTCDate(convertFromTimestamp(response?.firstAis?.nextPort?.time).toISOString()).toString()
          : undefined,
      };

      const lastPort = {
        unlcode: response.firstAis?.lastPort?.unlcode,
        name: response.firstAis?.lastPort?.name,
        time: response.firstAis?.lastPort?.time
          ? UTCDate(convertFromTimestamp(response?.firstAis?.lastPort?.time).toISOString()).toString()
          : undefined,
      };

      const timestamp = response.firstAis?.date ? UTCDate(convertFromTimestamp(response.firstAis.date).toISOString()).toString() : undefined;

      delete response.firstAis?.nextPort;
      delete response.firstAis?.lastPort;
      delete response.firstAis?.date;

      const res = {
        ...rest,
        weather: response.weather,
        ais: {
          ...response.firstAis,
          nextPort,
          lastPort,
          timestamp,
        },
        tags: response.tags,
        start_dttm: startTimestamp ? UTCDate(convertFromTimestamp(startTimestamp).toISOString()).toString() : undefined,
        end_dttm: endTimestamp ? UTCDate(convertFromTimestamp(endTimestamp).toISOString()).toString() : undefined,
        mediaStart: startTimestamp ? convertFromTimestamp(startTimestamp).toISOString() : undefined,
        mediaEnd: endTimestamp ? convertFromTimestamp(endTimestamp).toISOString() : undefined,
      };
      return res;
    }
    return thunkAPI.rejectWithValue(ERROR_MESSAGE);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message ? error.response.data.message : ERROR_MESSAGE);
  }
});

export const activityDetailsSlice = createSlice({
  name: "activityDetails",
  initialState,
  reducers: {
    cleanupActivityDetail(state) {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
    addTag(state, action: PayloadAction<Tag>) {
      if (state.data) {
        state.data.tags = [...state.data.tags, action.payload].sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    updateTag(state, action: PayloadAction<Tag>) {
      if (state.data) {
        state.data.tags = state.data.tags.map((tag) => (tag.tagId === action.payload.tagId ? action.payload : tag));
      }
    },
    removeTag(state, action: PayloadAction<string>) {
      if (state.data) {
        state.data.tags = state.data.tags.filter((tag) => tag.tagId !== action.payload);
      }
    },
    setActivityBlacklist(state, action: PayloadAction<Pick<Activity, "blacklist" | "blacklistComment">>) {
      if (state.data) {
        state.data.blacklist = action.payload.blacklist;
        state.data.blacklistComment = action.payload.blacklistComment;
      }
    },
    setActivityStared(state, action: PayloadAction<boolean>) {
      if (state.data) {
        state.data.stared = action.payload;
      }
    },
    setActivityWorkflowStatus(state, action: PayloadAction<WorkflowStatus>) {
      if (state.data) {
        state.data.workflowStatus = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivityDetails.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchActivityDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(fetchActivityDetails.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
      state.data = null;
    });
  },
});

export const { cleanupActivityDetail, setActivityBlacklist, addTag, setActivityStared, setActivityWorkflowStatus, removeTag, updateTag } =
  activityDetailsSlice.actions;
export default activityDetailsSlice.reducer;
