import styled from "styled-components";

const ProgressBarContainer = styled.div`
  position: relative;
  height: 18px;
  /* overflow: hidden; */
  outline: 1px solid #ededed;
  outline-offset: -1px;
  background-color: #f9fbfe;
`;

const ProgressFill = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 1s ease-in-out;
`;

export { ProgressBarContainer, ProgressFill };
